export const SET_USER_AMOUNT = "SET_USER_AMOUNT";

const action = {};

action.setUserAmount = (payload) => {
  return {
    type: SET_USER_AMOUNT,
    payload,
  };
};

export default action;
