import { makeStyles } from "@mui/styles";
import colors from "../../config/colors";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      display: "flex", flexDirection: "column"
    },
    [theme.breakpoints.up("lg")]: {
    display: "flex", flexDirection: "row" 
    },
  },
}));

export default useStyles;
