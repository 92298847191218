import { makeStyles } from "@mui/styles";
import colors from "../../config/colors";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    // backgroundColor: colors.CONTAINER_DARK,
    backgroundColor: "#f5f5f5",
    padding: "1rem",
  },
//   boxShadow: {
//     boxShadow: 1px 2px 14px 2px rgba(69,69,69,0.75);
// -webkit-box-shadow: 1px 2px 14px 2px rgba(69,69,69,0.75);
// -moz-box-shadow: 1px 2px 14px 2px rgba(69,69,69,0.75);
//   }
}));

export default useStyles;
