const colors = {
  PRIMARY: "#17447E",
  SECONDARY: "#1C1C55",
  BACKGROUND_WHITE: "#FBFBFB",
  HEADING_TEXT: "#17447E",
  HEADING_TEXT_LIGHT: "#969696",
  CONTAINER_DARK: "#17447E",
  CONTAINER_LIGHT: "#969696",
  BTN_CONTAINER: "#05B4EF",
  BLACK: "#000000",
  WHITE: "white",
  LIGHTGREY: "#f2f2f2",
  DARKGREY: "#3b3b3b",
  LIGHTBLACK: "#ababab"
};

export default colors;