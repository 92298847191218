import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import QRCode from "react-qr-code";
import Drawer from "../../components/Drawer";
import colors from "../../config/colors";
import Logo from "../../images/logoWOT.png";
import useStyles from "./style";
import { Alert, CircularProgress, Input, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import web3Object from "../../web3/web3";
import TenupABI from "../../ethereum/tenupsmart.json";
import BasicModal from "../../components/Modal";
import { useAlert } from "react-alert";
import userAmountAction from "../../redux/userAmount/action";
import Catamaran from "../../fonts/catamaran/Catamaran.ttf";
import Loader from "../../components/Loader";
import iconDown from "../../images/down-filled-triangular-arrow.png";
import up from "../../images/up.png";
import Web3 from "web3";

const Wallet = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <Drawer>
        <WalletComp />
      </Drawer>
    </>
  );
};

const WalletComp = (props) => {
  const alert = useAlert();
  const [loader, setLoader] = React.useState(false);
  const classes = useStyles(props);
  const user = useSelector((state) => state.userReducer);
  const [userbalance, setBalance] = useState("0.000");
  const getContract = (web3, abi, address) =>
    new web3.eth.Contract(abi, address);
  const [open, setOpen] = React.useState(false);
  const [openSend, setOpenSend] = React.useState(false);
  const [amount, setAmount] = React.useState();
  const [reciever, setReciever] = React.useState("");
  const dispatch = useDispatch();
  const { setUserAmount } = userAmountAction;

  useEffect(() => {
    getBalance();
  }, [user.users]);

  const getBalance = async () => {
    try {
      setLoader(true);
      const contract = getContract(
        web3Object.web3,
        TenupABI,
        process.env.REACT_APP_TENUP_SMART_ADDRESS
      );
      const balance = await contract.methods.balanceOf(user.users).call();
      const wei = parseFloat(
        web3Object?.web3?.utils?.fromWei(balance)
      )?.toFixed(3);

      dispatch(setUserAmount(wei));

      setBalance(wei);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const onRecieveClick = () => {
    try {
      if(user.users.length <= 0){
        alert.show("Please connect to wallet")
        return ;
      }
      if (user.users.length > 0) {
        setOpen(true);
      } else {
        alert("Connect to Wallet");
        setOpen(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSendClick = () => {
    try {
      if(user.users.length <= 0){
        alert.show("Please connect to wallet")
        return ;

      }
      if (user.users.length > 0) {
        setOpenSend(true);
      } else {
        alert("Connect to Wallet");
        setOpenSend(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const validator = () => {
    if (!reciever) {
      return "Reciever Address is required";
    }
    if (!amount) {
      return "Amount is required";
    }

    return false;
  };

  const sendFunction = async () => {
    try {
      setLoader(true);

      if (validator()) {
        throw new Error(validator());
      }

      const contract = getContract(
        web3Object.web3,
        TenupABI,
        process.env.REACT_APP_TENUP_SMART_ADDRESS
      );
      const contractRes = await contract.methods.balanceOf(user.users).call();
      if (
        parseInt(contractRes) > parseInt(web3Object.web3.utils.toWei(amount))
      ) {
        const transfer = await contract.methods
          .transfer(reciever, web3Object.web3.utils.toWei(amount))
          .send({
            from: user.users,
          });
        getBalance();
        setOpenSend(false);
        setReciever("");
        setAmount("");
      } else {
        throw new Error("Insufficient Funds in account");
      }

      alert.show("Funds sent successfully");
      setLoader(false);
    } catch (e) {
      setLoader(false);
      setOpenSend(false);
      setReciever("");
      setAmount("");
      alert.show("Error while sending");
    }
  };

  const handleClose = () => setOpen(false);
  const handleSendClose = () => setOpenSend(false);

  return (
    <>
      <div>
        <Card
          elevation={5}
          sx={{ minWidth: 20, maxWidth: 300, borderRadius: 5 }}
        >
          <CardContent
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                color: colors.LIGHTBLACK,
                p: 1,
                fontWeight: "bold",
                fontFamily: Catamaran,
              }}
            >
              TUP
            </Typography>
            <div style={{ marginLeft: "auto" }}>
              <img style={{ width: "70px" }} src={Logo} />
            </div>
          </CardContent>
          <CardContent style={{ display: "flex", flexDirection: "row" }}>
            <Typography
              sx={{
                fontSize: 22,
                color: colors.BLACK,
                mt:-5,
                pl: 2,
                fontWeight: "bold",
              }}
            >
              {user?.users?.length ? userbalance : "0.000"}
            </Typography>
          </CardContent>
          <CardContent
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingLeft: "30px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: 5,
                backgroundColor: colors.BTN_CONTAINER,
                borderRadius: 20,
              }}
            ></div>
          </CardContent>
        </Card>

        <Card
          elevation={5}
          sx={{
            minWidth: 20,
            width: { sm: 500, lg: "auto" },
            marginTop: 4,
            borderRadius: 5,
            mb: 3,
          }}
        >
          <CardContent
            style={{
              display: "flex",
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                color: colors.LIGHTBLACK,
                p: 2,
                fontWeight: "bold",
              }}
            >
              TUP
            </Typography>
          </CardContent>
          <CardContent style={{ display: "flex", flexDirection: "row" }}>
            <Typography
              sx={{
                fontSize: 22,
                color: colors.BLACK,
                pl: 2,
                mt:-3,
                fontWeight: "BOLD",
              }}
            >
              {user?.users?.length ? userbalance : "0.000"}
            </Typography>
          </CardContent>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: "30px",
              mt:-3
            }}
          >
            <Typography
              style={{ width: "100%" }}
              sx={{
                fontSize: { xs: 15, md: 18 },
                pr: { xs: 1, md: 0 },
                color: colors.CONTAINER_LIGHT,
                fontWeight: "normal",
              }}
            >
              Total Balance in your account
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "80%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={(e) => onSendClick()}
                sx={{
                  width: "100%",
                  color: "white",
                  p: 2,
                  background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
                  boxShadow: 4,
                  borderRadius: 3,
                }}
              >
                {/* <img style={{width:30, marginRight: 5}} src={Logo}/> */}
                <img
                  style={{
                    width: 20,
                    marginRight: 10,
                    transform: "rotate(180deg)",
                  }}
                  src={iconDown}
                />
                Send
              </Button>
              <Button
                variant="outlined"
                sx={{
                  borderColor: colors.CONTAINER_LIGHT,
                  width: "100%",
                  color: colors.CONTAINER_LIGHT,
                  ml: 2,
                  p: 2,
                  borderRadius: 3,
                  boxShadow: 4,
                }}
                onClick={(e) => onRecieveClick()}
              >
                                <img
                  style={{
                    width: 20,
                    marginRight: 10,
                  }}
                  src={up}
                />
                Recieve
              </Button>
            </div>
          </CardContent>
        </Card>
        <BasicModal
          heading={"Recieve Money"}
          open={open}
          isCopyToken={true}
          onCancel={handleClose}
          userAcc={user.users}
          isLowOnWidth={true}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <QRCode size={200} value={user.users} />
          </div>
        </BasicModal>

        <BasicModal
          heading={"Send Money"}
          open={openSend}
          isCopyToken={true}
          onCancel={handleSendClose}
          userAcc={user.users}
          onSend={sendFunction}
          isLowOnWidth={false}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label>Enter ERC20 Address</label>
            <Input
              disableUnderline={true}
              value={reciever}
              onChange={(e) => setReciever(e.target.value)}
              startAdornment={
                <InputAdornment sx={{ p: 2 }} position="start">
                  <QRCode size={40} value={user.users} />
                </InputAdornment>
              }
              id="input-with-icon-adornment"
              sx={{
                backgroundColor: colors.LIGHTGREY,
                border: "none",
                borderRadius: 2,
                height: 60,
                marginTop: 1,

                fontWeight: "bold",
                marginRight: 1,
                pr: 1,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20,
            }}
          >
            <label>Amount</label>
            <Input
              disableUnderline={true}
              value={amount}
              type="number"
              onChange={(e) => setAmount(e.target.value)}
              startAdornment={
                <InputAdornment sx={{ p: 1 }} position="start">
                  <img
                    style={{ width: 40, paddingLeft: 10, paddingRight: 10 }}
                    src={Logo}
                  />
                </InputAdornment>
              }
              id="input-with-icon-adornment"
              sx={{
                backgroundColor: colors.LIGHTGREY,
                border: "none",
                borderRadius: 2,
                height: 60,
                fontWeight: "bold",
                marginTop: 1,
                marginRight: 1,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 20,
            }}
          >
            <div
              style={{
                width: " 100%",
                marginRight: 10,
              }}
            >
              <Button
                onClick={() => setAmount(userbalance / 4)}
                style={{
                  width: " 100%",
                  height: 50,
                  borderRadius: 10,
                  backgroundColor: colors.LIGHTGREY,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  color: colors.CONTAINER_DARK,
                }}
              >
                1/4
              </Button>
            </div>
            <div
              style={{
                width: " 100%",
                marginRight: 10,
              }}
            >
              <Button
                onClick={() => setAmount(userbalance / 2)}
                style={{
                  width: " 100%",
                  height: 50,
                  borderRadius: 10,
                  backgroundColor: colors.LIGHTGREY,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  color: colors.CONTAINER_DARK,
                }}
              >
                Half
              </Button>
            </div>
            <div
              style={{
                width: " 100%",
                marginRight: 10,
              }}
            >
              <Button
                onClick={() => setAmount(userbalance)}
                style={{
                  width: "100%",
                  height: 50,
                  borderRadius: 10,
                  backgroundColor: colors.LIGHTGREY,
                  display: "flex",
                  justifyContent: "center",
                  color: colors.CONTAINER_DARK,
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                All
              </Button>
            </div>
          </div>
        </BasicModal>
        {/* <div style={{position:'absolute',  bottom: 5, right: 0, left:0}}>
        <Alert  severity="error">This is an error alert — check it out!</Alert>
      </div> */}

        {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 20,
              backgroundColor: "red",
              width: "80%",
            }}
          >
            {alertError && (
              <div style={{ position: "absolute", right: 10 }}></div>
            )}
            {alertSuccess && (
              <div style={{ position: "absolute", right: 10 }}></div>
            )}
          </div> */}
      </div>
      <Loader open={loader} />
    </>
  );
};
export default Wallet;
