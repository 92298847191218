import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import colors from "../../config/colors";
import { display } from "@mui/system";
import web3Object from "../../web3/web3";
import { Input, InputAdornment } from "@mui/material";
import BigNumber from "bignumber.js";
import Logo from "../../images/logoWOT.png";
import { useSelector } from "react-redux";
import TOKENABI from "../../ethereum/erc20_abi.json";
import StakingABi from "../../ethereum/stakeTenupv2.json";

const toHex = (amount) => "0x" + amount.toString(16);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {sx: "80%", md: "50%"},
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 40,
  p: 4,
  outline: 'none'
};

const headingCSS = {
  fontWeight: "bold",
  color: colors.DARKGREY,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  alignItems: "center",
};

export default function StakeMore(props) {
  const getContract = (web3, abi, address) =>
    new web3.eth.Contract(abi, address);
  const { setOpen, open, isLowOnWidth } = props;
  const [ amount, setAmount ] = React.useState(0);
  const userbalance = useSelector((state) => state.userAmountReducer);
  const user = useSelector((state) => state.userReducer);

  // console.log(userbalance);
  const stakeMore = async () => {
    if(user.users.length <= 0){
      props.alert.show("Please connect to wallet")
      return ;
    }
    if (user.users) {
      props.setLoader(true);
      let val = amount.toString();
      let k = 1000;
      if (val) {
          val = new BigNumber(web3Object.web3.utils.toWei(val));
          val = toHex(val);
          let STAKE1 = getContract(
            web3Object.web3,
            StakingABi,
            process.env.REACT_APP_STAKING_ADDRESS
          );
          let TOKEN = getContract(
            web3Object.web3,
            TOKENABI,
            process.env.REACT_APP_STAKING_TOKEN_ADDRESS
          );
          let blnc = await TOKEN.methods.balanceOf(user.users).call();
          if (parseInt(blnc) > val) {
            let app = await props.isApproved(
              TOKEN,
              user.users,
              process.env.REACT_APP_STAKING_ADDRESS,
              val
            );
            if (app) {
              await STAKE1.methods.increaseStaking(val).send({
                from: user.users,
              });
              props.getStakerInfo(user.users);
              props.getStats();
              props.alert.show("Staked successfully")
            } else {
              props.alert.show("Tokens not approved");
            }
          } else {
            props.alert.show("Insufficient Tokens");
          }
      } else {
        props.alert.show("Enter amount to stake");
      }
    } else {
      props.alert.show("Connect Wallet");
    }

    await props.getBalance()
    props.setLoader(false);
    setOpen(false);
    setAmount(false);
  }
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...style, width: isLowOnWidth ? {sx: "100%", md:"30%" } : {sx: "100%", md: "50%"} }}>
          <Typography
            sx={headingCSS}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Stake More
          </Typography>
          <div style={{ marginTop: 20 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 20,
              }}
            >
              <label>Amount</label>
              <Input
                disableUnderline={true}
                value={amount}
                type="number"
                onChange={(e) => setAmount(e.target.value)}
                startAdornment={
                  <InputAdornment sx={{ p: 1 }} position="start">
                    <img
                      style={{ width: 40, paddingLeft: 10, paddingRight: 10 }}
                      src={Logo}
                    />
                  </InputAdornment>
                }
                id="input-with-icon-adornment"
                sx={{
                  backgroundColor: colors.LIGHTGREY,
                  border: "none",
                  borderRadius: 2,
                  height: 60,
                  fontWeight: "bold",
                  marginTop: 1,
                  marginRight: 1,
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  width: " 100%",
                  marginRight: 10,
                }}
              >
                <Button
                  onClick={() => setAmount(userbalance.userAmount / 4)}
                  style={{
                    width: " 100%",
                    height: 50,
                    borderRadius: 10,
                    backgroundColor: colors.LIGHTGREY,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                    color: colors.CONTAINER_DARK,
                  }}
                >
                  1/4
                </Button>
              </div>
              <div
                style={{
                  width: " 100%",
                  marginRight: 10,
                }}
              >
                <Button
                  onClick={() => setAmount(userbalance.userAmount / 2)}
                  style={{
                    width: " 100%",
                    height: 50,
                    borderRadius: 10,
                    backgroundColor: colors.LIGHTGREY,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                    color: colors.CONTAINER_DARK,
                  }}
                >
                  Half
                </Button>
              </div>
              <div
                style={{
                  width: " 100%",
                  marginRight: 10,
                }}
              >
                <Button
                  onClick={() => setAmount(userbalance.userAmount)}
                  style={{
                    width: "100%",
                    height: 50,
                    borderRadius: 10,
                    backgroundColor: colors.LIGHTGREY,
                    display: "flex",
                    justifyContent: "center",
                    color: colors.CONTAINER_DARK,
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  All
                </Button>
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            sx={{
              background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",

              width: "100%",
              color: "white",
              height: 50,
              marginTop: 3,
            }}
            onClick={stakeMore}
          >
            Stake More
          </Button>
          <Button
            variant="contained"
            sx={{
              background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",

              width: "100%",
              height: 50,
              color: "white",
              marginTop: 3,
            }}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
