import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FormControl, Input, InputAdornment, InputLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Drawer from "../../components/Drawer";
import colors from "../../config/colors";
import Logo from "../../images/logoWOT.png";
import SwapIc from "../../images/swap.png";
import TOKENABI from "../../ethereum/erc20_abi.json";
import swapAbi from "../../ethereum/swap.json";
import copy from "../../images/copy.png";
import UserAmountAction from "../../redux/userAmount/action";

import useStyles from "./style";
import { Box } from "@mui/system";
import { useAlert } from "react-alert";
import api from "../../api";
import Loader from "../../components/Loader";
import axios from "axios";
import web3Object from "../../web3/web3";
import Web3 from "web3";

const Swap = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <Drawer>
        <VirtualComp />
      </Drawer>
    </>
  );
};

const VirtualComp = (props) => {
  const [clicked, setClicked] = useState(1);
  const [token, setToken] = useState("");
  const alert = useAlert();
  const user = useSelector((state) => state.userReducer);
  const [loader, setLoader] = React.useState(false);
  const tok = localStorage.getItem("token");
  const [claims, setClaims] = React.useState([]);
  const { setUserAmount } = UserAmountAction;
  const dispatch = useDispatch();
  const getContract = (web3, abi, address) =>
    new web3.eth.Contract(abi, address);

  useEffect(() => {
    setClaims([]);
    if (user.users) {
      getBalance();

      const token = localStorage.getItem("token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        checkToken();
        getClaims();
      }
    }
  }, []);

  useEffect(() => {
    if (user.users) {
      const token = localStorage.getItem("token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        checkToken();
        getClaims();
      }
    }
  }, [tok]);

  useEffect(() => {
    getBalance();

    if (user.users) {
      const token = localStorage.getItem("token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        checkToken();
        getClaims();
      }
    }
  }, [user.users]);

  const getClaims = async () => {
    try {
      const token = await checkToken();
      let signature = await localStorage.getItem("sign");

      if (token && signature) {
        setLoader(true);

        const payload = {
          generateAddress: token,
          signature: signature,
          address: user?.users?.toString()?.toLowerCase(),
        };

        const res = await api.getClaims(payload);

        // console.log("res", res);
        setClaims(res);
        setLoader(false);
      } else {
        setLoader(false);
        setClaims([]);
      }
    } catch (e) {}
  };

  const checkToken = async () => {
    try {
      setLoader(true);
      const payload = {
        address: user?.users?.toString()?.toLowerCase(),
      };
      const res = await api.checkIfToken(payload);

      if (res) {
        // console.log(res);
        if (res.address) {
          setToken(res.address);
          setLoader(false);
          return res.address;
        } else {
          setToken("");
          setLoader(false);
          return false;
        }
      } else {
        setToken("");
        setLoader(false);
        return false;
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
      return false;
    }
  };

  const claimFunc = async (item) => {
    try {
      setLoader(true);

      const sign = localStorage.getItem("sign");
      const payload = {
        amount: item.amount,
        txid: item.tx_received,
        signature: sign,
        address: user?.users?.toString()?.toLowerCase(),
      };
      const res = await api.claim(payload);
      let SWAP_ = getContract(
        web3Object.web3,
        swapAbi,
        process.env.REACT_APP_SWAP_CONTRACT_ADDRESS
      );
      let res__ = await SWAP_.methods
        .withdrawTokens(res.encode, res.sign)
        .send({ from: user?.users });

      const payload_ = {
        txid: item.tx_received,
        signature: sign,
        address: user?.users?.toString()?.toLowerCase(),
      };
      const res_claimed = await api.claimed(payload_);

      if (!res_claimed) {
        throw new Error("Error");
      }

      await getBalance();
      await getClaims();
      setLoader(false);
      alert.show("Claimed successfull");
    } catch (e) {
      console.log(e);
      console.log(e.message);
      setLoader(false);
      alert.show("Cannot claim the amount");
    }
  };

  const getBalance = async () => {
    try {
      let TOKEN = getContract(
        web3Object.web3,
        TOKENABI,
        process.env.REACT_APP_STAKING_TOKEN_ADDRESS
      );
      const balance = await TOKEN.methods.balanceOf(user.users).call();
      const wei = parseFloat(web3Object.web3?.utils?.fromWei(balance))?.toFixed(
        3
      );
      dispatch(setUserAmount(wei));
    } catch (e) {
      console.log(e);
    }
  };

  const buttonClicked = (param) => {
    setClicked(param);
  };

  const genToken = async () => {
    try {
      if (!user.users) {
        alert.show("Connect to metamask");
        return;
      }

      setLoader(true);

      let signature = await localStorage.getItem("sign");
      const payload = {
        address: user?.users?.toString()?.toLowerCase(),
        signature: signature,
      };
      const genTk = await api.generateToken(payload);
      if (genTk) {
        setToken(genTk);
      }

      await checkToken();
      setLoader(false);
    } catch (e) {
      console.log(e);
      alert.show("Error while generating token, try again.");
      setLoader(false);
    }
  };

  const copyToken = () => {
    navigator.clipboard.writeText(token);
    alert.show("Token copied successfully!");
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 30,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          elevation={5}
          sx={{
            minWidth: { xs: 500, md: 600 },
            maxWidth: 600,
            maxHeight: clicked === 2 ? "65vh" : "auto",
            overflowY: clicked === 2 ? "scroll" : "hidden",
            borderRadius: 5,
          }}
        >
          <CardContent
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: 20,
                color: colors.BLACK,
                p: 2,
                fontWeight: "bold",
                borderRadius: 3,
              }}
            >
              SWAP
            </Typography>
          </CardContent>

          <CardContent
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <Box
              sx={{
                borderRadius: 3,
                display: "flex",
                flexDirection: "row",
                width: "100%",
                backgroundColor: colors.LIGHTGREY,
                // boxShadow: "0 0 10px",
                boxShadow: 4,
              }}
            >
              <Button
                variant="contained"
                onClick={() => buttonClicked(1)}
                disableRipple
                sx={{
                  borderRadius: 3,
                  background:
                    clicked === 1
                      ? "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)"
                      : colors.LIGHTGREY,
                  color:
                    clicked === 1 ? colors.LIGHTGREY : colors.CONTAINER_DARK,
                  width: "100%",
                  "&:hover": {
                    background:
                      clicked === 1
                        ? "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)"
                        : colors.LIGHTGREY,
                    borderRadius: "none",
                    boxShadow: 0,
                  },
                  boxShadow: 0,
                }}
              >
                Create
              </Button>
              <Button
                variant="contained"
                onClick={() => buttonClicked(2)}
                disableRipple
                sx={{
                  borderRadius: 3,
                  background:
                    clicked === 2
                      ? "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)"
                      : colors.LIGHTGREY,
                  width: "100%",
                  color:
                    clicked === 2 ? colors.LIGHTGREY : colors.CONTAINER_DARK,
                  boxShadow: 5,
                  padding: 1.5,
                  boxShadow: 0,
                  "&:hover": {
                    background:
                      clicked === 2
                        ? "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)"
                        : colors.LIGHTGREY,
                    borderRadius: "none",
                    boxShadow: 0,
                  },
                }}
              >
                Claim
              </Button>
            </Box>
          </CardContent>
          {clicked === 1 && (
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <Box
                sx={{
                  padding: 2,
                  marginTop: 0,
                  backgroundColor: colors.LIGHTGREY,
                  borderRadius: 4,
                  boxShadow: 4,
                  mb: 1,
                }}
              >
                <p
                  style={{
                    color: "#a1a7bb",
                    fontSize: "15px",
                    lineHeight: "30px",
                    fontWeight: "normal",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <label
                      style={{
                        textAlign: "center",
                        color: colors.HEADING_TEXT_LIGHT,
                        fontSize: 16,
                        width: "100%",
                      }}
                    >
                      How it works
                      <hr
                        style={{
                          width: "100%",
                          height: 1.5,
                          backgroundColor: colors.LIGHTBLACK,
                          border: "none",
                        }}
                      />
                    </label>
                  </div>
                  A very simple and easy method for swapping. <br></br>
                  Follow the Steps: <br />
                  1. Connect you metamask wallet <br />
                  2. Press "Generate Deposit Address"
                  <br />
                  3. Send the correct swapping TUP on that generated address.
                  <br />
                  4. After depositing old Tenup on the below generated address.
                  Go to claim section.
                  <br />
                  5. Redeem your all claimable new tenup smart tokens.
                </p>
              </Box>
            </CardContent>
          )}

          {clicked === 1 && token && user.users && (
            <CardContent
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: 20,
                marginRight: 20,
                marginTop: -20,
              }}
            >
              <FormControl style={{ width: "100%" }} variant="standard">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignContent: "start",
                  }}
                >
                  <label
                    style={{
                      color: colors.HEADING_TEXT_LIGHT,
                      fontSize: 12,
                      marginBottom: 5,
                    }}
                  >
                    Token
                  </label>
                </div>
                <Input
                  disabled
                  sx={{
                    backgroundColor: colors.LIGHTGREY,
                    border: "none",
                    borderRadius: 2,
                    height: 50,
                    boxShadow: 3,
                    p: 1.5,
                  }}
                  disableUnderline={true}
                  value={user.users ? token : ""}
                  id="input-with-icon-adornment"
                />
              </FormControl>
            </CardContent>
          )}
          {clicked === 1 && user.users && (
            <CardContent style={{ marginLeft: 20, marginRight: 20 }}>
              {!token && (
                <Button
                  variant="contained"
                  sx={{
                    background:
                      "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
                    width: "100%",
                    color: colors.LIGHTGREY,
                    borderRadius: 2,
                    boxShadow: 5,
                    padding: 1.5,
                  }}
                  onClick={() => genToken()}
                >
                  Generate
                </Button>
              )}
              {token && user.users && (
                <Button
                  variant="contained"
                  sx={{
                    background:
                      "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
                    width: "100%",
                    color: colors.LIGHTGREY,
                    borderRadius: 2,
                    boxShadow: 5,
                    padding: 1.5,
                  }}
                  onClick={copyToken}
                >
                  Copy to clipborad
                  <img src={copy} style={{ width: 30, marginLeft: 10 }} />
                </Button>
              )}
            </CardContent>
          )}

          {clicked === 2 && user.users && claims?.length > 0 && (
            <CardContent style={{ marginLeft: 20, marginRight: 20 }}>
              {claims.map((item) => (
                <Box sx={{ boxShadow: 4, p: 2, borderRadius: 4, mb: 3 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <img src={Logo} style={{ width: 50 }}></img>
                      <h3 style={{ marginLeft: 15 }}>Tenup</h3>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                        alignItems: "flex-end",
                      }}
                    >
                      <Box style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              background: item.approved ? "#28a745" : "#ffc107",
                              padding: 0.5,
                              borderRadius: 1.5,
                              color: item.approved ? "white" : "black",
                              fontWeight: 400,
                              boxShadow: 4,
                              fontSize: 10,
                              mt: -0.5,
                              mr: 1,
                            }}
                          >
                            {item.approved ? "approved" : "un-approved"}
                          </Box>
                        </div>
                        <span
                          style={{ fontSize: 10, color: colors.LIGHTBLACK }}
                        >
                          Tx Id:{" "}
                          {item?.tx_received?.toString()?.length > 15
                            ? item?.tx_received.slice(0, 15) + "..."
                            : item?.tx_received?.toString()}
                          {/* Tx Id: {item.tx_received} */}
                        </span>
                      </Box>

                      <span>Amount</span>
                      <span style={{ fontSize: 18, fontWeight: "bold" }}>
                        {item.amount} TUP
                      </span>
                    </div>
                  </div>
                  {item.approved && !item.status && (
                    <div style={{ width: "100%" }}>
                      <Button
                        onClick={(e) => claimFunc(item)}
                        sx={{
                          width: "100%",
                          borderRadius: 2,
                          boxShadow: 4,
                          mb: 1,
                          mt: 3,
                          color: "white",
                          background:
                            "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",

                          "&:hover": {
                            opacity: 0.9,
                            transition: "all 0.3s",
                          },
                        }}
                      >
                        Claim
                      </Button>
                    </div>
                  )}
                  {item.status && item.approved && (
                    <div style={{ width: "100%" }}>
                      <Box
                        sx={{
                          width: "100%",
                          borderRadius: 2,
                          p: 1,
                          textAlign: "center",
                          boxShadow: 4,
                          mb: 1,
                          mt: 2,
                          color: "white",
                          opacity: 0.5,
                          background:
                            "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
                        }}
                      >
                        Claimed
                      </Box>
                    </div>
                  )}
                </Box>
              ))}
            </CardContent>
          )}

          {clicked === 2 && user.users && claims?.length <= 0 && (
            <CardContent style={{ marginLeft: 20, marginRight: 20 }}>
              <Box sx={{ boxShadow: 4, p: 2, borderRadius: 4, mb: 3 }}>
                <p style={{ textAlign: "center" }}>No transactions available</p>
              </Box>
            </CardContent>
          )}
        </Card>
      </div>
      <Loader open={loader} />
    </>
  );
};
// Create Crosschain Asset
export default Swap;
