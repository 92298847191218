import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import QRCode from "react-qr-code";
import Drawer from "../../components/Drawer";
import colors from "../../config/colors";
import Logo from "../../images/logoWOT.png";
import useStyles from "./style";
import { Alert, CircularProgress, Input, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import web3Object from "../../web3/web3";
import TenupABI from "../../ethereum/tenupsmart.json";
import BasicModal from "../../components/Modal";
import { useAlert } from "react-alert";
import userAmountAction from "../../redux/userAmount/action";
import Catamaran from "../../fonts/catamaran/Catamaran.ttf";
import Loader from "../../components/Loader";
import iconDown from "../../images/down-filled-triangular-arrow.png";
import up from "../../images/up.png";
import Web3 from "web3";
import api from "../../api";
import axios from "axios";

const Approval = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <Drawer>
        <ApprovalComp />
      </Drawer>
    </>
  );
};

const ApprovalComp = (props) => {
  const alert = useAlert();
  const [loader, setLoader] = React.useState(false);
  const classes = useStyles(props);
  const user = useSelector((state) => state.userReducer);
  const [userbalance, setBalance] = useState("0.000");
  const getContract = (web3, abi, address) =>
    new web3.eth.Contract(abi, address);
  const [open, setOpen] = React.useState(false);
  const [openSend, setOpenSend] = React.useState(false);
  const [amount, setAmount] = React.useState();
  const [reciever, setReciever] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [selection, setSelected] = React.useState([]);
  const dispatch = useDispatch();
  const { setUserAmount } = userAmountAction;

  const columns = [
    { field: "id", headerName: "Tx Id", flex: 1 },
    { field: "address", headerName: "Address", flex: 1 },
    { field: "generatedAddress", headerName: "Generated Address", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
  ];

  useEffect(() => {
    if (user.users) {
      const token = localStorage.getItem("token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        fetchClaims();
      }
    }
  }, []);

  const fetchClaims = async () => {
    try {
      setRows([]);
      setLoader(true);
      const res = await api.getAPprovalsAdmin();
      let temp = [];
      // console.log(res)
      for (let i = 0; i < res.length; i++) {
        temp.push({ id: res[i].tx_received, address: res[i]?.user?.address, generatedAddress: res[i]?.user?.generatedAddress, amount: res[i].amount });
      }
      setRows(temp);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const approve = async () => {
    try {
      if (!selection.length) {
        throw new Error("Select something to approve");
      }

      setLoader(true);
      const payload = {
        approvals: selection,
      };

      const res = await api.aproveTrans(payload);
      await fetchClaims();

      alert.show("Aproved Successfully");
      setLoader(false);
    } catch (e) {
      console.log(e);
      alert.show(e.message);
      setLoader(false);
    }
  };

  return (
    <>
      <div style={{ height: "56.5vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          onSelectionModelChange={(e) => setSelected(e)}
        />
      </div>
      <Button
        sx={{
          width: "100%",
          // backgroundColor: colors.BTN_CONTAINER,
          background:
            "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
          color: "white",
          boxShadow: 4,
          p: 2,
        }}
        onClick={() => approve()}
      >
        Approve Selections
      </Button>
      <Loader open={loader} />
    </>
  );
};
export default Approval;
