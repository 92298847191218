import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import colors from "../../config/colors";
import { display } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {sx: "80%", md: "50%"},
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 40,
  p: 4,
  outline: 'none'
};

const headingCSS = {
  fontWeight: "bold",
  color: colors.DARKGREY,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  alignItems: "center",
};

export default function BasicModal(props) {
  const { onCancel, open, heading, children, isCopyToken, userAcc, onSend, isLowOnWidth } = props;

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...style, width: isLowOnWidth ? {sx: "100%", md:"30%" } : {sx: "100%", md: "50%"} }}>
          <Typography
            sx={headingCSS}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {heading}
            {isCopyToken && (
              <Button
                onClick={() => navigator.clipboard.writeText(userAcc)}
                style={{ marginLeft: "auto", 
               }}
              >
                Copy Address
              </Button>
            )}
          </Typography>
          <div style={{ marginTop: 20 }}>{children}</div>
          {onSend &&
          
          <Button
            variant="contained"
            sx={{
              background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",

              width: "100%",
              color: "white",
              height: 50,
              marginTop: 3,
            }}
            onClick={onSend}
          >
            Send
          </Button>
          }
          <Button
            variant="contained"
            sx={{
              background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",

              width: "100%",
              height: 50,
              color: "white",
              marginTop: 3,
            }}
            onClick={onCancel}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
