// import axios from "../helpers/axios";
import axios from '../config/axios';

const api = {};

api.login = function (body) {
  const url = `${process.env.REACT_APP_BASE_URL}/create/user`;

  return axios.post(url, body);
};

api.checkandGenerateNonce = function (body) {
  const url = `${process.env.REACT_APP_BASE_URL}/check/user`;

  return axios.post(url, body);
};

api.verifySIgn = function (body) {
  const url = `${process.env.REACT_APP_BASE_URL}/verify/sign`;

  return axios.post(url, body);
};

api.generateToken = function (body) {
  const url = `${process.env.REACT_APP_BASE_URL}/swap/generate`;

  return axios.post(url, body);
};

api.checkIfToken = function (body) {
  const url = `${process.env.REACT_APP_BASE_URL}/check/token`;

  return axios.post(url, body);
};

api.getClaims = function (body) {
  const url = `${process.env.REACT_APP_BASE_URL}/get/transaction/user`;

  return axios.post(url, body);
};

api.getAPprovalsAdmin = function (body) {
  const url = `${process.env.REACT_APP_BASE_URL}/get/transaction/admin`;

  return axios.post(url, body);
};

api.aproveTrans = function (body) {
  const url = `${process.env.REACT_APP_BASE_URL}/approve`;

  return axios.post(url, body);
};

api.claim = function (body) {
  const url = `${process.env.REACT_APP_BASE_URL}/claim`;

  return axios.post(url, body);
};

api.claimed = function (body) {
  const url = `${process.env.REACT_APP_BASE_URL}/claimed`;
  return axios.post(url, body);
};


api.addLocker = function (body) {
  const url = `${process.env.REACT_APP_BASE_URL_WAQARZAKA}/locked/add`;
  return axios.post(url, body);
};
api.getAllLcoked = function (body) {
  const url = `${process.env.REACT_APP_BASE_URL_WAQARZAKA}/locked/get`;
  return axios.post(url, body);
};
export default api;
