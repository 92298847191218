import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import Approval from "../screens/AdminApproval";
import Presale from "../screens/Presale";
import Swap from "../screens/Swap";
import Virtual from "../screens/Virtual";

import Wallet from "../screens/Wallet";
import Signal from "../screens/Signal";
import LockTUP from "../screens/lockTUP";

const Router = (props) => {
  const Location = useLocation();
  const user = useSelector((state) => state.userReducer);

  typeof window.ethereum != "undefined" &&
    window.ethereum.on("chainChanged", function (accounts) {
      window.location.reload();
    });

  window.isBitKeep &&
    !!window.bitkeep.ethereum &&
    window?.bitkeep?.ethereum.on("chainChanged", function (accounts) {
      window.location.reload();
    });

  return (
    <>
      {Location.pathname === "/" ||
      Location.pathname === "/wallet" ||
      Location.pathname === "/signal" ||
      Location.pathname === "/virtual-mining" ||
      Location.pathname === "/swap" ||
      Location.pathname === "/presale" ||
      Location.pathname === "/lock-tup" ||
      (Location.pathname === "/approval" &&
        user?.users?.toString().toLowerCase() ===
          process.env.REACT_APP_ADMIN_WALLET.toString().toLowerCase()) ? (
        <Routes>
          <Route exact={true} path="/" element={<Wallet />} />
          <Route exact={true} path="/signal" element={<Signal />} />
          <Route exact={true} path="/wallet" element={<Wallet />} />
          <Route exact={true} path="/virtual-mining" element={<Virtual />} />
          <Route exact={true} path="/swap" element={<Swap />} />
          <Route exact={true} path="/presale" element={<Presale />} />
          <Route exact={true} path="/approval" element={<Approval />} />
          {/* <Route exact={true} path="/lock-tup" element={<LockTUP />} /> */}
        </Routes>
      ) : (
        <>
          <Routes>
            <Route exact={true} path="*" element={<Wallet />} />
          </Routes>
          <Navigate to="/wallet" />
        </>
      )}
    </>
  );
};

export default Router;
