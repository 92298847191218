// function Signals(params) {

//     return <>
//         <h1>Signals</h1>
//     </>
// }

// export default Signals;

import {
    TextField,
} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
// import Drawer from "@mui/material/Drawer";
import moment from "moment";
import Drawer from "../../components/Drawer";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import Logo from "../../images/logo.png";
import Menu from "../../images/menu.png";
import Wallet from "../../images/wallet.png";
import Presale from "../../images/presale.png";
import Swap from "../../images/swap.png";
import Virtual from "../../images/virtual.png";
import Collectibalities from "../../images/collectibalities.png";
import WalletLight from "../../images/walletLight.png";
import PresaleLight from "../../images/presaleLight.png";
import SwapLight from "../../images/swapLight.png";
import VirtualLight from "../../images/virtualizationLight.png";
import Logout from "../../images/logout.png";
import CollectibalitiesLight from "../../images/collactibleLight.png";
import colors from "../../config/colors";
import WalletCompenet from "../../components/../screens/Wallet";
import userWalletActions from "../../redux/users/action";
import UserAmountActions from "../../redux/userAmount/action";
import Modal from "@mui/material/Modal";
import {
    checkChain_,
    getAccount,
    web3Initializer,
    windowEth,
} from "../../config/metamaskWalletConnector";
import TenupABI from "../../ethereum/tenupsmart.json";
import web3Object from "../../web3/web3";
import BasicModal from "../../components/Drawer/index";
import WalletConnect from "../../images/walletConnect.png";
import cross from "../../images/cross.png";
import editIcon from "../../images/editIcon.png";
import add from "../../images/add.png";
import bitkeep from "../../images/BitKeep logo for wallet connect.svg";
import Meta from "../../images/meta.png";
import campton from "../../fonts/campton/CamptonBlack.otf";
import catamaran from "../../fonts/catamaran/Catamaran.ttf";
import Web3 from "web3";
import { useAlert } from "react-alert";
import "../../components/Drawer/index.css";
import axiosObject from "../../config/axios";
import api from "../../api";
import axios from "axios";
import Loader from "../../components/Loader/index";
import {
    checkBitKeepInstalled,
    checkChain_bitkeep,
    initializeBitKeep,
} from "../../config/bitkeep";
import abi from "../../ethereum/stakeTenupv2.json"

const drawerWidth = 240;

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { sx: "80%", md: "50%" },
    bgcolor: "background.paper",
    borderRadius: 5,
    boxShadow: 40,
    p: 4,
    outline: 'none'
};


const APIURL = process.env.REACT_APP_BASE_URL

function Signals(props) {
    const { window, children } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [selected, setSelected] = React.useState("Wallet");
    const location = useLocation();
    const [loader, setLoader] = React.useState(false);
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const { setUser } = userWalletActions;
    const { setUserAmount } = UserAmountActions;
    const [isConnected, setConnected] = React.useState(0);
    const user = useSelector((state) => state.userReducer);
    const userAmount = useSelector((state) => state.userAmountReducer);
    const [userbalance, setBalance] = React.useState("0");
    const getContract = (web3, abi, address) =>
        new web3.eth.Contract(abi, address);
    const alert = useAlert();
    let walletCon = localStorage.getItem("con");



    React.useEffect(() => {
        // if (user.users) {
        //     checkChain();
        //     checkIfTokenExist();
        // }
        isStaker()
        if (IsStaker) {
            getSignal()
        }else if(user?.users &&(process.env.REACT_APP_ADMIN_WALLET).toLowerCase() === ((user?.users).toLowerCase())){
            getSignal()
        }

    }, []);

    const checkChain = async (param) => {
        try {
            let id = param;
            if (!param) {
                let walletNo = localStorage.getItem("con");

                if (!walletNo) {
                    await Logoutusr();
                }

                id = walletNo;
            }

            const chain = await checkChain_();
            if (parseInt(id) === 3) {
                const checkChain_bitkeep_ = await checkChain_bitkeep();
                // console.log(checkChain_bitkeep_);
                if (checkChain_bitkeep_ !== false) {
                    if (
                        parseInt(checkChain_bitkeep_) !==
                        parseInt(process.env.REACT_APP_CHAIN_ID)
                    ) {
                        // console.warn(checkChain_bitkeep_);
                        // console.warn(process.env.REACT_APP_CHAIN_ID);
                        alert.show("Connect to mainnet");
                        await Logoutusr();
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    alert.show("Connect to mainnet");
                    throw new Error("Chain not matched");
                }
            } else {
                if (chain !== false) {
                    if (parseInt(chain) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
                        alert.show("Connect to mainnet");
                        await Logoutusr();
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    if (id === 2) {
                        alert.show("Please install metamask and refresh your browser.");
                        return false;
                    } else if (id === 1) {
                        return true;
                    }
                }
            }
        } catch (e) {
            console.log(e);
            return false;
        }
    };


    const walletConnectValidator = async (chain) => {
        try {
            // console.log(parseInt(chain) !== parseInt(process.env.REACT_APP_CHAIN_ID));
            // console.warn(parseInt(chain), parseInt(process.env.REACT_APP_CHAIN_ID));

            if (parseInt(chain) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
                alert.show("Please connect to mainnet");
                return false;
            } else {
                return true;
            }
        } catch (e) {
            console.log(e);
        }
    };

    const checkIfTokenExist = async () => {
        if (user.users) {
            const token = localStorage.getItem("token");
            const sign = localStorage.getItem("sign");
            if (token && sign) {
                axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                await validateTokenAndSign();
            } else {
                await Logoutusr();
            }
        }
    };

    const validateTokenAndSign = async () => {
        try {
            const res = await api.checkIfToken({
                address: user?.users?.toString()?.toLowerCase(),
            });

            if (!res) {
                alert.show("Session Expired or invalid, Please connect again");
                await Logoutusr();
                return;
            } else {
                const sign = localStorage.getItem("sign");
                const payld = {
                    signature: sign,
                    address: user.users,
                };
                const signVerify = await api.verifySIgn(payld);

                if (!signVerify) {
                    alert.show("Session Expired or invalid, Please connect again");
                    await Logoutusr();
                }
            }
        } catch (e) {
            alert.show("Session Expired or invalid, Please connect again");
            await Logoutusr();
        }
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const Logoutusr = () => {
        localStorage.removeItem("persist:root");
        localStorage.removeItem("token");
        localStorage.removeItem("sign");
        localStorage.removeItem("walletconnect");
        localStorage.removeItem("con ");

        dispatch(setUser(""));
        dispatch(setUserAmount("0.00"));
    };

    React.useEffect(() => {
        if (location.pathname === "/") {
            setSelected("Wallet");
        }
        if (location.pathname === "/wallet") {
            setSelected("Wallet");
        } else if (location.pathname === "/swap") {
            setSelected("Swap");
        } else if (location.pathname === "/signal") {
            setSelected("Signals");
        } else if (location.pathname === "/virtual-mining") {
            setSelected("Virtual Mining");
        } else if (location.pathname === "/swap") {
            setSelected("Swap");
        } else if (location.pathname === "/presale") {
            setSelected("Presale");
        } else if (location.pathname === "/approval") {
            setSelected("Approvals");
        }else if (location.pathname === "/lock-tup") {
            setSelected("Access with TenUp");
        }
    }, [location.pathname]);

    const redirect = (param) => {
        if (param === "Wallet") {
            setSelected("Wallet");
            navigation("/wallet");
        }
        if (param === "Signals") {
            setSelected("Signals");
            navigation("/signal");
        }
        if (param === "Virtual Mining") {
            setSelected("Virtual Mining");
            navigation("/virtual-mining");
        }
        if (param === "/lock-tup") {
            setSelected("/Lock-TUP");
            navigation("/lock-tup");
        }
        if (param === "Swap") {
            setSelected("Swap");
            navigation("/swap");
        }
        if (param === "Presale") {
            setSelected("Presale");
            navigation("/presale");
        }
        if (param === "Approvals") {
            setSelected("Approvals");
            navigation("/approval");
        }
    };

    // const drawer = (
    //     <div>
    //         <div>
    //             <ListItemIcon
    //                 style={{
    //                     padding: 20,
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     alignItems: "center",
    //                 }}
    //             >
    //                 <img src={Logo} style={{ width: "75%", height: "auto" }} alt="Icon" />
    //             </ListItemIcon>
    //             <List
    //                 style={{
    //                     height: "60vh",
    //                     padding: 20,
    //                     flexDirection: "column",
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     alignItems: "center",
    //                 }}
    //             >
    //                 <ListItem
    //                     key={"Wallet"}
    //                     onClick={() => redirect("Wallet")}
    //                     disablePadding
    //                 >
    //                     <ListItemButton>
    //                         <ListItemIcon>
    //                             <img
    //                                 src={selected === "Wallet" ? Wallet : WalletLight}
    //                                 alt="Icon"
    //                             />
    //                         </ListItemIcon>
    //                         <ListItemText
    //                             style={{ color: selected === "Wallet" ? "black" : "#ababab" }}
    //                             primary={"Wallet"}
    //                         />
    //                     </ListItemButton>
    //                 </ListItem>

    //                 <ListItem
    //                     key={"Presale"}
    //                     onClick={() => redirect("Presale")}
    //                     disablePadding
    //                 >
    //                     <ListItemButton>
    //                         <ListItemIcon>
    //                             <img
    //                                 src={selected === "Presale" ? Presale : PresaleLight}
    //                                 alt="Icon"
    //                             />
    //                         </ListItemIcon>
    //                         <ListItemText
    //                             primary={"Presale"}
    //                             style={{ color: selected === "Presale" ? "black" : "#ababab" }}
    //                         />
    //                     </ListItemButton>
    //                 </ListItem>


    //                 <ListItem
    //                     key={"Signals"}
    //                     onClick={() => redirect("Signals")}
    //                     disablePadding
    //                 >
    //                     <ListItemButton>
    //                         <ListItemIcon>
    //                             <img
    //                                 src={selected === "Signals" ? Presale : PresaleLight}
    //                                 alt="Icon"
    //                             />
    //                         </ListItemIcon>
    //                         <ListItemText
    //                             primary={"Signals"}
    //                             style={{ color: selected === "Signals" ? "black" : "#ababab" }}
    //                         />
    //                     </ListItemButton>
    //                 </ListItem>



    //                 <ListItem
    //                     key={"Virtual Mining"}
    //                     onClick={() => redirect("Virtual Mining")}
    //                     disablePadding
    //                 >
    //                     <ListItemButton>
    //                         <ListItemIcon>
    //                             <img
    //                                 src={selected === "Virtual Mining" ? Virtual : VirtualLight}
    //                                 alt="Icon"
    //                             />
    //                         </ListItemIcon>
    //                         <div>
    //                             <ListItemText
    //                                 style={{
    //                                     color: selected === "Virtual Mining" ? "black" : "#ababab",
    //                                 }}
    //                                 primary={"Virtual Mining"}
    //                             />
    //                         </div>
    //                     </ListItemButton>
    //                 </ListItem>
    //                 <ListItem
    //                     onClick={() => redirect("Swap")}
    //                     key={"Swap"}
    //                     disablePadding
    //                 >
    //                     <ListItemButton style={{ display: "flex", alignItems: "center" }}>
    //                         <ListItemIcon>
    //                             <img src={selected === "Swap" ? Swap : SwapLight} alt="Icon" />
    //                         </ListItemIcon>
    //                         <div>
    //                             <div
    //                                 style={{
    //                                     display: "flex",
    //                                     justifyContent: "center",
    //                                     flexDirection: "column",
    //                                 }}
    //                             >
    //                                 <span
    //                                     style={{
    //                                         color: selected === "Swap" ? "black" : "#ababab",
    //                                         fontSize: 16,
    //                                     }}
    //                                 >
    //                                     Swap
    //                                 </span>
    //                             </div>
    //                         </div>
    //                     </ListItemButton>
    //                 </ListItem>
    //                 {user?.users?.toString().toLowerCase() ===
    //                     process.env.REACT_APP_ADMIN_WALLET.toString().toLowerCase() && (
    //                         <ListItem
    //                             key={"Approvals"}
    //                             onClick={() => redirect("Approvals")}
    //                             disablePadding
    //                         >
    //                             <ListItemButton>
    //                                 <ListItemIcon>
    //                                     <img
    //                                         src={selected === "Approvals" ? Virtual : VirtualLight}
    //                                         alt="Icon"
    //                                     />
    //                                 </ListItemIcon>
    //                                 <div>
    //                                     <ListItemText
    //                                         style={{
    //                                             color: selected === "Approvals" ? "black" : "#ababab",
    //                                         }}
    //                                         primary={"Approvals"}
    //                                     />
    //                                 </div>
    //                             </ListItemButton>
    //                         </ListItem>
    //                     )}
    //                 <ListItem
    //                     key={"Collectibles"}
    //                     disabled={true}
    //                     // onClick={() => setSelected("Collectibles")}
    //                     disablePadding
    //                 >
    //                     <ListItemButton style={{ display: "flex", alignItems: "center" }}>
    //                         <ListItemIcon>
    //                             <img
    //                                 src={
    //                                     selected === "Collectibles"
    //                                         ? Collectibalities
    //                                         : CollectibalitiesLight
    //                                 }
    //                                 alt="Icon"
    //                             />
    //                         </ListItemIcon>
    //                         <div
    //                             style={{
    //                                 display: "flex",
    //                                 justifyContent: "center",
    //                                 flexDirection: "column",
    //                             }}
    //                         >
    //                             <span style={{ color: "#ababab", fontSize: 16 }}>
    //                                 Collectiables
    //                             </span>
    //                             <span
    //                                 style={{
    //                                     color: "#ababab",
    //                                     fontSize: 13,
    //                                     marginTop: -5,
    //                                     fontWeight: 100,
    //                                 }}
    //                             >
    //                                 coming soon
    //                             </span>
    //                         </div>
    //                     </ListItemButton>
    //                 </ListItem>
    //                 <ListItem></ListItem>
    //             </List>
    //         </div>
    //         {user.users.length > 0 && (
    //             <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
    //                 <List style={{ padding: 20 }}>
    //                     <Button
    //                         variant="contained"
    //                         onClick={(e) => Logoutusr()}
    //                         sx={{
    //                             backgroundColor: colors.CONTAINER_DARK,
    //                             width: "100%",
    //                             color: "white",
    //                         }}
    //                         startIcon={<img src={Logout} alt="Icon" />}
    //                     >
    //                         Logout
    //                     </Button>
    //                 </List>
    //             </div>
    //         )}
    //     </div>
    // );

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [TEXT, setTEXT] = React.useState("");
    const [FILE, setFILE] = React.useState("");

    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = (item) => {
        // console.log(item);
        setOpen2(true);
        setTEXT2(item?.content)
    }
    const handleClose2 = () => { setOpen2(false); setItemIdToUpdate(""); }
    const [TEXT2, setTEXT2] = React.useState("");
    const [FILE2, setFILE2] = React.useState("");
      const [selectedImg, setSelectedImg] = React.useState(null);

    const [itemIdToUpdate, setItemIdToUpdate] = React.useState("");

    const [Signals, setSignals] = React.useState([]);

    async function createSignal() {
        const form = new FormData()
        if(TEXT == ""){
            return alert.show("Title is required");
                
        }
        form.append('text', TEXT)
        form.append('FILE', FILE)
        form.append('wallet', process.env.REACT_APP_ADMIN_WALLET)

        await axios.post(`${APIURL}/create-signal`, form)
            .then(res => {
                // console.log(res.data.msg);
                alert.show(res.data.msg);
                getSignal()
                setTEXT("")
                setFILE("")
                handleClose()
            })
            .catch(err => {
                alert.show(err.response.data.error);
                console.log(err.response.data.error);
            })
    }

    async function getSignal() {
        await axios.get(`${APIURL}/get-signals`)
            .then(res => {
                // console.log(res.data.data);
                setSignals(res.data.data)
            })
            .catch(err => {
                console.log(err.response.data.error);
            })
    }

    async function updateSignal() {
        const form = new FormData()
        form.append('text', TEXT2)
        form.append('FILE', FILE2)
        form.append('signalID', itemIdToUpdate)
        form.append('wallet', process.env.REACT_APP_ADMIN_WALLET)

        // console.log(itemIdToUpdate);

        if (itemIdToUpdate != "") {
            await axios.post(`${APIURL}/update-signal`, form)
                .then(res => {
                    // console.log(res.data.msg);
                    alert.show(res.data.msg);
                    getSignal()
                    handleClose2()
                    setTEXT2("")
                    setFILE2("")
                })
                .catch(err => {
                    alert.show(err.response.data.error);
                    console.log(err.response.data.error);
                })

        }
    }

    async function deleteSignal(id) {
        await axios.post(`${APIURL}/delete-signal`, {
            signalID: id,
            wallet: process.env.REACT_APP_ADMIN_WALLET
        })
            .then(res => {
                // console.log(res.data.msg);
                alert.show(res.data.msg);
                getSignal()
            })
            .catch(err => {
                alert.show(err.response.data.error);
                console.log(err.response.data.error);
            })
    }


    // validate user is a staker or not
    const [IsStaker, setIsStaker] = React.useState(false);
    async function isStaker() {
        // console.log(web3Object.web3);

        const userWallet = await (web3Object.web3).eth.getAccounts()
        // console.log(userWallet);

        if (userWallet.length > 0) {
            const STAKING_Contrct = await new (web3Object.web3).eth.Contract(abi, process.env.REACT_APP_STAKING_ADDRESS)
            // console.log(STAKING_Contrct);
            const userStaking = await STAKING_Contrct.methods.stakers(userWallet[0]).call();
            // console.log("userStaking =>", userStaking);
            // console.log("stakedAmount =>", userStaking.stakedAmount);
            if (parseInt(userStaking.stakedAmount) > 0) {
                setIsStaker(true);
                getSignal()
            }
        }
    }

    const dateFormat = (datePram) => {
        const date = new Date(datePram);
    
        // return date.toLocaleString();
        return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    };

    const container =
        window !== undefined ? () => window().document.body : undefined;
        
    return (
        <Drawer >
            <div className="signalsMain">

                    {
                        (user?.users && ((process.env.REACT_APP_ADMIN_WALLET).toLowerCase() === (user?.users)?.toLowerCase())) &&
                        <button className="addBtn" onClick={() => handleOpen()}>
                            + 
                        </button>
                    }
                    {
                        IsStaker || (user?.users && (process.env.REACT_APP_ADMIN_WALLET).toLowerCase() === (user?.users).toLowerCase()) ?
                                <section style={{ border: "none", marginBottom: "200px" }}>
                                    {Signals.length == 0 &&
                                    <h2 className="text-center">No signal available right now.</h2>
                                    }

                                    {
                                        Signals.map((item, i) => {
                                            return <div key={i} className="signalCard" >
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <h3 className="my-1">Tenup Team</h3>
                                                
                                                </div>
                                                    
                                                    <TextPost item={item} key={i} />

                                                {
                                                    (item?.image !== null && item?.image !== "") &&
                                                    <div className="imgDiv">
                                                        <img src={`${APIURL}/product/img/${item?.image}`} alt="image"  />
                                                    </div>
                                                }

                                                <div className="bottom-cont-posts">
                                                {
                                                    (process.env.REACT_APP_ADMIN_WALLET).toLowerCase() === (user.users).toLowerCase() &&
                                                    <>
                                                        <div style={{ display: "flex" }}>
                                                            <div className="editIco" onClick={() => { setItemIdToUpdate(item._id); handleOpen2(item) }}>
                                                                <img src={editIcon} alt="" />
                                                            </div>
                                                            <div className="cross" onClick={() => deleteSignal(item._id)}>
                                                                <img src={cross} alt="" />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div className="dateTool time">
                                                    {moment(item?.createdAt).fromNow()}
                                                    <div className="fullDate">{dateFormat(item?.createdAt)}</div>
                                                </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </section>
                            :
                            <div className="noStakerM">
                                {/* <h2 className="text-center">Only Staker can view signals</h2> */}
                                <p className=" mb-2">Are you ready to see some exclusive crypto signals? Only Tenup stakers have access to this valuable resource. Don't worry though, if you're not a staker yet, simply click here to start virtual mining on Tenup's dapp platform.</p>
                                <Link className="btnStake" to="/virtual-mining" >STAKING</Link>
                                {/* <p className="mt-4 mb-2">And if you still need to get your hands on some Tenup tokens, head over to OKEx and make a purchase. The world of crypto awaits! </p>
                                <a className="btnStake" target="_blank" href="https://www.okx.com/trade-spot/tup-usdt" >OKX</a> */}
                            </div>
                        }



                {/* Create Modal */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">

                            <TextField
                                style={{ width: "100%" }}
                                variant="standard"
                                onChange={(e) => setTEXT(e.target.value)}
                                label="Enter Title"
                                name="signal"
                                value={TEXT}
                                multiline
                            />

                        </Typography>
                        {/* <input type="file" style={{ marginTop: "15px" }} onChange={(e) => setFILE(e.target.files[0])} /> */}

                        <div className="mt-3 mb-3 fileUpload-modal">
                            <label>
                            <input
                                name="file"
                                // onChange={onFileChange}
                                onChange={(e) => setFILE(e.target.files[0])}
                                accept="audio/*,video/*,image/*"
                                type={"file"}
                            />
                            {FILE == "" && <img src={add} />}
                            {/* {console.log(FILE)} */}
                            {FILE && (
                                <img className="img_sel" src={URL.createObjectURL(FILE)} />
                            )}
                            </label>
                        </div>
                        <div style={{ display: "flex", justifyContent: "Center" }}>
                            <button onClick={() => createSignal()} style={{cursor:'pointer', marginTop: "15px", border: "none", borderRadius: "10px", padding: "10px 20px",cursor:'pointer' }} >
                                Save
                            </button>
                        </div>
                    </Box>
                </Modal>

                {/* Update Modal */}
                <Modal
                    open={open2}
                    onClose={handleClose2}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">

                            <TextField
                                style={{ width: "100%" }}
                                variant="standard"
                                onChange={(e) => setTEXT2(e.target.value)}
                                label="Enter Title"
                                name="signal"
                                value={TEXT2}
                                multiline
                            />

                        </Typography>

                        {/* <input type="file" style={{ marginTop: "15px" }} onChange={(e) => setFILE2(e.target.files[0])} /> */}
                        <div className="mt-3 mb-3 fileUpload-modal">
                            <label>
                            <input
                                name="file"
                                // onChange={onFileChange}
                                onChange={(e) => setFILE2(e.target.files[0])}
                                accept="audio/*,video/*,image/*"
                                type={"file"}
                            />
                            {FILE2 == "" && <img src={add} />}
                            {FILE2 && (
                                <img className="img_sel" src={URL.createObjectURL(FILE2)} />
                            )}
                            </label>
                        </div>
                        <div style={{ display: "flex", justifyContent: "Center" }}>
                            <button onClick={() => updateSignal()} style={{ marginTop: "15px", border: "none", borderRadius: "10px", padding: "10px 20px" }} className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium css-1up7e5e-MuiButtonBase-root-MuiButton-root" >
                                Save
                            </button>
                        </div>
                    </Box>
                </Modal>


                <Loader open={loader} />
            </div>
        </Drawer>
    );
}

const TextPost = ({item,key}) => {
    const [readMore, setReadMore] = React.useState(false);
  
    // const handleShowMore = (key) => {
    //   var post = document.getElementById(`post${key}`)
    //   post.style.overflow="unset"
    //   post.style.maxHeight="unset"
    // } 
    // const ShowMoreHeight = (key) => {
    //   var post = document.getElementById(`post${key}`)
    //   console.log(post.innerText.includes('\n')); 
    //   console.log(post.getClientRects().length); 
    // }
      // const claimToEth = () =>{
      //   var temp = "This is a string\n sasa";
      //   var count = (temp.match(/\n/g) || []).length;
      //   console.log(count);
      // }
      React.useEffect(()=>{
        // console.log( (item?.content.match(/\n/g) || []).length )
  
        // console.log();
  
      },[])
    return (
      <>  
        <p className="post" id={`post${key}`} >
          {/* {readMore ? item?.signal : `${item?.signal.substring(0, 80)}...`} */}
          {readMore ? item?.content :
          (item?.content.match(/\n/g) || []).length > 2
          ?
           item?.content.substring(0, item?.content.indexOf("\n", item?.content.indexOf("\n") + 1))
           :
           item?.content
          }
        </p>
        {(item?.content.match(/\n/g) || []).length > 2 &&
        <div className="showMoreB" onClick={() => setReadMore(!readMore)}>{readMore ? "show less" : "  read more"}</div>
        }
      </>
  
    )
  }
Signals.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Signals;