import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Alert, CircularProgress, Input, InputAdornment, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Modal from "@mui/material/Modal";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from "../../components/Drawer";
import colors from "../../config/colors";
import Logo from "../../images/logoWOT.png";
import background from "../../images/bg.png";

import useStyles from "./style";
import web3Object from "../../web3/web3";
import StakingABi from "../../ethereum/stakeTenupv2.json";
import TOKENABI from "../../ethereum/erc20_abi.json";
import TenupABI from "../../ethereum/tenupsmart.json";
import { useDispatch, useSelector } from "react-redux";
import BigNumber from "bignumber.js";
import { useAlert } from "react-alert";
import bg_claim from "../../images/Group 1947-1.png";
import Loader from "../../components/Loader";
import UserAmountAction from "../../redux/userAmount/action";
import Web3 from "web3";
import iconDown from "../../images/down-filled-triangular-arrow.png";
import calc from "../../images/keys.png";
import save from "../../images/save-money.png";
import StakeMore from "./stakeMore";

const toHex = (amount) => "0x" + amount.toString(16);
const Virtual = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <Drawer>
        <VirtualComp />
      </Drawer>
    </>
  );
};

const VirtualComp = (props) => {
  const getContract = (web3, abi, address) =>
    new web3.eth.Contract(abi, address);
  const user = useSelector((state) => state.userReducer);
  const classes = useStyles(props);
  const [stakedAmount, setStakedAmount] = useState(0);
  const [rewardAmount, setRewardAmount] = useState(0);
  const [userReward, setInputReward] = useState("");
  const [staking, setStaking] = useState("");
  const [rwrd, setFinalRwrd] = useState("");
  const [show, setshow] = useState(false);
  const [oSt, setOst] = useState("");
  const [stakersActive, setStakersActive] = useState(0);
  const [currentAPY, setCurrentAPY] = useState(0);
  const [currentAPY0, setCurrentAPY0] = useState(0);
  const [currentAPY1, setCurrentAPY1] = useState(0);
  const [currentAPY2, setCurrentAPY2] = useState(0);
  const [currentAPY3, setCurrentAPY3] = useState(0);
  const [totalStaked, setTotalStaked] = useState(0);
  const [calcPkg, setCalcPkg] = useState(0);
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [stakeMoreModal, setStakeMoreModal] = useState(false);
  const [openUnstakeModal, setOpenUnstakeModal] = useState(false);
  const [UnStakeVal, setUnStakeVal] = useState(0);
  const { setUserAmount } = UserAmountAction;
  const dispatch = useDispatch();
  const web33 = new Web3(process.env.REACT_APP_GET_RPC);
  // console.log(process.env.REACT_APP_STAKING_ADDRESS);
  let STAKE = getContract(
    web33,
    StakingABi,
    process.env.REACT_APP_STAKING_ADDRESS
  );
  let TENUP = getContract(
    web3Object.web3,
    TenupABI,
    process.env.REACT_APP_STAKING_ADDRESS
  );
  const getStats = async () => {
    setLoader(true);
    
    let totalToks = await STAKE.methods.totalStaked().call();
    let apy = await STAKE.methods.currentAPY().call();
    //let apy0 = await STAKE.methods.currentAPYPackage(0).call();
    let apy1 = await STAKE.methods.currentAPYPackage(1).call();
    let apy2 = await STAKE.methods.currentAPYPackage(2).call();
    let apy3 = await STAKE.methods.currentAPYPackage(3).call();
    let activeSta = await STAKE.methods.stakersActive().call();
    setStakersActive(activeSta);
    setCurrentAPY(apy / 100);
    setCurrentAPY0(apy / 100);
    setCurrentAPY1(apy1 / 100);
    setCurrentAPY2(apy2 / 100);
    setCurrentAPY3(apy3 / 100);
    setTotalStaked(new BigNumber(totalToks) / 10 ** 18);
    setLoader(false);
  };

  useEffect(() => {
    getStats();
    if(user.users) {
      getBalance();
    }
  }, []);

  useEffect(() => {
    if (user.users) {
      getStakerInfo(user?.users?.toString()?.toLowerCase());
    } else {
      setStakedAmount(0);
    }
  }, [user.users]);

  const isApproved = async (tok, owner, approvedAddress, totalAmount) => {
    try {
      let r = await tok.methods.allowance(owner, approvedAddress).call();
      // console.log(r);
      if (parseInt(r) <= totalAmount) {
        let val = new BigNumber(web3Object.web3.utils.toWei("10000000000"));
        let finPrice = toHex(val);
        let a = await tok.methods.approve(approvedAddress, finPrice).send({
          from: owner,
        });
        // console.log(a);
        return true;
      } else {
        return true;
      }
    } catch (e) {
      console.log("Approve Issue", e);
      return false;
    }
  };

  const stakeToken = async (pkg) => {
    if(user.users.length <= 0){
      alert.show("Please connect to wallet")
      return ;

    }
    if (user.users) {
      setLoader(true);
      let val = staking;
      let k = 1000;
      if (val) {
        let isValid = false, errMsg = '';
        if (pkg === 0){
          if (parseFloat(val) >= 50) {
            isValid = true;
          }
          else{
            errMsg = "For Teletraan Staking, Minimum: 50 TUP Need To Be Locked";
          }
        }
        else if (pkg === 1){
          if (parseFloat(val) >= 40*k && parseFloat(val) <= 100*k){
            isValid = true;
          }
          else{
            errMsg = "For Bumblebee Staking, Minimum: 40000 TUP, Maximum: 100000 TUP Need To Be Locked";
          }
        }
        else if (pkg === 2){
          if (parseFloat(val) > 100*k && parseFloat(val) <= 200*k){
            isValid = true;
          }
          else{
            errMsg = "For Megatron Staking, Minimum: >100000 TUP, Maximum: 200000 TUP Need To Be Locked";
          }
        }
        else if (pkg === 3){
          if (parseFloat(val) >= 250*k && parseFloat(val) <= 1*k*k){
            isValid = true;
          }
          else{
            errMsg = "For Optimus Prime Staking, Minimum: 250000 TUP, Maximum: 1000000 TUP Need To Be Locked";
          }
        }
        if (isValid) {
          val = new BigNumber(web3Object.web3.utils.toWei(val));
          val = toHex(val);
          let STAKE1 = getContract(
            web3Object.web3,
            StakingABi,
            process.env.REACT_APP_STAKING_ADDRESS
          );
          let TOKEN = getContract(
            web3Object.web3,
            TOKENABI,
            process.env.REACT_APP_STAKING_TOKEN_ADDRESS
          );
          let blnc = await TOKEN.methods.balanceOf(user.users).call();
          if (parseInt(blnc) > val) {
            let app = await isApproved(
              TOKEN,
              user.users,
              process.env.REACT_APP_STAKING_ADDRESS,
              val
            );
            if (app) {
              await STAKE1.methods.StakeAmount(val, pkg).send({
                from: user.users,
              });
              getStakerInfo(user.users);
              getStats();
              alert.show("Staked successfully")
            } else {
              alert.show("Tokens not approved");
            }
          } else {
            alert.show("Insufficient Tokens");
          }
        }
        else{
          alert.show(errMsg)
        }
      } else {
        alert.show("Enter amount to stake");
      }
    } else {
      alert.show("Connect Wallet");
    }

    await getBalance()
    setLoader(false);
  };

  const getBalance = async () => {
    try {
      let TOKEN = getContract(
        web3Object.web3,
        TOKENABI,
        process.env.REACT_APP_STAKING_TOKEN_ADDRESS
      );
      const balance = await TOKEN.methods.balanceOf(user.users).call();
      const wei = parseFloat(
        web3Object.web3?.utils?.fromWei(balance)
      )?.toFixed(3);
      dispatch(setUserAmount(wei))

    } catch (e) {
      console.log(e);
    }
  };


  const getStakerInfo = async (address) => {
    setLoader(true);
    let res = await STAKE.methods.stakers(address).call();
    if (new BigNumber(res.stakedAmount) > 0) {
      let rew = await STAKE.methods.showRewards(address).call();
      setRewardAmount( new BigNumber(rew));
    }
    let amnt = new BigNumber(res.stakedAmount) / 10 ** 18
    setStakedAmount(amnt);
    setLoader(false);
  };

  const calculateRew = async () => {
    setLoader(true);
    // console.log(calcPkg);
    let val = userReward, k = 1000, isValid = false, errMsg = '';
    if (calcPkg === 0){
      if (parseFloat(val) >= 50) {
        isValid = true;
      }
      else{
        errMsg = "For PKG0, Minimum: 50 TUP";
      }
    }
    else if (calcPkg === 1){
      if (parseFloat(val) >= 40*k && parseFloat(val) <= 100*k){
        isValid = true;
      }
      else{
        errMsg = "For PKG1, Minimum: 40000TUP, Maximum: 100000TUP";
      }
    }
    else if (calcPkg === 2){
      if (parseFloat(val) > 100*k && parseFloat(val) <= 200*k){
        isValid = true;
      }
      else{
        errMsg = "For PKG2, Minimum: >100000TUP, Maximum: 200000TUP";
      }
    }
    else if (calcPkg === 3){
      if (parseFloat(val) >= 250*k && parseFloat(val) <= 1*k*k){
        isValid = true;
      }
      else{
        errMsg = "For PKG3, Minimum: 250000TUP, Maximum: 1000000TUP";
      }
    }

    if (isValid) {
      if (val) {
      val = new BigNumber(web3Object.web3.utils.toWei(val));
        val = toHex(val);
        let res = await STAKE.methods.calculatePerDayRewards(val, calcPkg).call();
        // console.log(res);
        setFinalRwrd(res / 10 ** 18);
        setshow(true);
        setOst(userReward);
        setLoader(false);
      } else {
        alert.show("Transaction Failed");

        setLoader(false);
      }
    } else {
      alert.show(errMsg);
      setLoader(false);
    }
  };

  const validateUnstake = async () => {
    // console.log(stakedAmount);
    // console.log((new BigNumber(stakedAmount)).toNumber());
    // console.log(UnStakeVal);
    var check =false;
    if(new BigNumber(stakedAmount) < parseFloat(UnStakeVal) ) {
      check = true
      return check
    }
    return check
  }

  const unstakeTokenAmount = async () => {
    setLoader(true);
    if (user.users) {
      
      if(UnStakeVal <= 0) {
        setLoader(false);
        return alert.show("Please enter amount.")
      }
      let validate = await validateUnstake()
      if(validate) {
        setLoader(false);
        return alert.show("You entered greater amount than you stake.")
      }
      let STAKE1 = getContract(
        web3Object.web3,
        StakingABi,
        process.env.REACT_APP_STAKING_ADDRESS
      );
      try {
        // console.log(UnStakeVal);
        let val = new BigNumber(web3Object.web3.utils.toWei(UnStakeVal));
        val = toHex(val);
        // console.log(val);
        let a = await STAKE1.methods.unstakeAmount(val).send({
          from: user.users,
        });
        getStakerInfo(user.users);
        getStats();
        alert.show("Unstaked successfull");
      } catch (e) {
        alert.show("Failed");
        console.log(e);
      }
    } else {
      alert.show("Connect Wallet");
    }

    await getBalance()
    setLoader(false);
  };

  const unstakeToken = async () => {
    setLoader(true);
    if (user.users) {
      let STAKE1 = getContract(
        web3Object.web3,
        StakingABi,
        process.env.REACT_APP_STAKING_ADDRESS
      );
      try {
        let a = await STAKE1.methods.endStake().send({
          from: user.users,
        });
        getStakerInfo(user.users);
        getStats();
        alert.show("Unstaked successfull");
      } catch (e) {
        alert.show("Failed");
        console.log(e);
      }
    } else {
      alert.show("Connect Wallet");
    }

    await getBalance()
    setLoader(false);
  };

  const showRewards = async () => {
    setLoader(true);
    let a = await STAKE.methods.showRewards(user.users).call();
    // setRewardPerDay(web3.utils.fromWei(a));
    // console.log("loader", a);
    setLoader(false);
  };

  useEffect(() => {
    if (user.users) {
      showRewards();
    }
  }, [user]);
  const reInvestReward = async () => {
    if (rewardAmount <= 0) {
      alert.show("You cannot re-invest amount less than or equal to 0");
      return;
    }
    setLoader(true);
    if (user.users) {
      let STAKE = getContract(
        web3Object.web3,
        StakingABi,
        process.env.REACT_APP_STAKING_ADDRESS
      );
      try {
        let a = await STAKE.methods.reInvest().send({
          from: user.users,
        });
        // console.log("aekjfoqijmfoc", a);
        getStakerInfo(user.users);
          alert.show("Claimed Successfully")
      } catch (e) {
        alert.show("Failed");
      }
    } else {
      alert.show("Connect Wallet");
    }

    await getBalance()
    setLoader(false);
  }
  const claimReward = async () => {
    if (rewardAmount <= 0) {
      alert.show("You cannot claim amount less than or equal to 0");
      return;
    }
    setLoader(true);
    if (user.users) {
      let STAKE = getContract(
        web3Object.web3,
        StakingABi,
        process.env.REACT_APP_STAKING_ADDRESS
      );
      try {
        let a = await STAKE.methods.redeem().send({
          from: user.users,
        });
        // console.log("aekjfoqijmfoc", a);
        getStakerInfo(user.users);
          alert.show("Claimed Successfully")
      } catch (e) {
        alert.show("Failed");
      }
    } else {
      alert.show("Connect Wallet");
    }

    await getBalance()
    setLoader(false);
  };

  // const checkPerDayReward = async () => {
  //   // let val = document.getElementById('perdayrew').value;
  //   if (val && parseFloat(val) > 0){
  //     val = new BigNumber(web3Object.web3.utils.toWei(val));
  //     val = toHex(val);
  //     let STAKE = getContract(web3Object.web3, StakingABi, process.env.REACT_APP_STAKING_ADDRESS);
  //     let a = await STAKE.methods.calculatePerDayRewards(val).call();
  //     setRewardPerDay(web3Object.web3.utils.fromWei(a));
  //   }
  // }

  const setStakingCH = (val) => {
    setStakingCH(val);
  };

  return (
    <>
      <div className={classes.main}>
        <Card
          elevation={5}
          sx={{ width: { xs: "100%", lg: "50%" }, borderRadius: 5 }}
        >
          <CardContent
            style={{
              display: "flex",
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                color: colors.BLACK,
                p: 2,
                fontWeight: "bold",
              }}
            >
              Staking
            </Typography>
          </CardContent>
          {new BigNumber(stakedAmount) <= 0 && (
            <CardContent style={{ display: "flex", flexDirection: "row" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  color: colors.CONTAINER_LIGHT,
                  pl: 2,
                  fontWeight: "normal",
                }}
              >
                Enter amount to stake
              </Typography>
            </CardContent>
          )}
          <div
            style={{
              paddingLeft: 30,
              paddingBottom: 30,
              display: "flex",
              alignItems: "center",
            }}
          >
            {new BigNumber(stakedAmount) <= 0 && (
              <input
                type="number"
                value={staking}
                onChange={(e) => setStaking(e.target.value)}
                style={{
                  border: "none",
                  borderBottom: `1px solid ${colors.CONTAINER_LIGHT}`,
                  outline: "none",
                  fontSize: 15,
                }}
              ></input>
            )}
            {new BigNumber(stakedAmount) > 0 && (
              <>
              <p style={{ fontWeight: 700 }}>
                {parseFloat(
                 (stakedAmount.toString())
                )?.toFixed(1)}
               {" "} TUP
              </p>
              <Button
                variant="contained"
                sx={{
                  // width: "100%",
                  background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
                  color: "white",
                  boxShadow: 4,
                  // p: 2,
                  ml: 2
                }}
                onClick={() => setStakeMoreModal(true)}
              >
                <img style={{ width: 20, marginRight: 10 }} src={iconDown} />
                Stake More
              </Button>
              </>
            )}
          </div>
          <div>
            {new BigNumber(stakedAmount) <= 0 && (
              <>
              <Box sx={{
                display: 'flex'
              }}>
                <Box sx={{
                  width: "50%",
                  padding: '10px'
                }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      // backgroundColor: colors.BTN_CONTAINER,
                      background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
                      color: "white",
                      boxShadow: 4,
                      p: 2,
                    }}
                    onClick={() => stakeToken(0)}
                  >
                    {/* <i class="fal fa-arrow-alt-from-left"></i> */}
                    <img
                      style={{
                        width: 20,
                        marginRight: 10,
                        transform: "rotate(180deg)",
                      }}
                      src={iconDown}
                    />
                    Stake Teletraan
                  </Button>
                </Box>
                <Box sx={{
                  width: "50%",
                  padding: '10px'
                }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      // backgroundColor: colors.BTN_CONTAINER,
                      background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
                      color: "white",
                      boxShadow: 4,
                      p: 2,
                    }}
                    onClick={() => stakeToken(1)}
                  >
                    {/* <i class="fal fa-arrow-alt-from-left"></i> */}
                    <img
                      style={{
                        width: 20,
                        marginRight: 10,
                        transform: "rotate(180deg)",
                      }}
                      src={iconDown}
                    />
                    Stake Bumblebee
                  </Button>
                </Box>
              </Box>

              <Box sx={{
                display: 'flex'
              }}>
                <Box sx={{
                  width: "50%",
                  padding: '10px'
                }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      // backgroundColor: colors.BTN_CONTAINER,
                      background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
                      color: "white",
                      boxShadow: 4,
                      p: 2,
                    }}
                    onClick={() => stakeToken(2)}
                  >
                    {/* <i class="fal fa-arrow-alt-from-left"></i> */}
                    <img
                      style={{
                        width: 20,
                        marginRight: 10,
                        transform: "rotate(180deg)",
                      }}
                      src={iconDown}
                    />
                    Stake Megatron
                  </Button>
                </Box>
                <Box sx={{
                  width: "50%",
                  padding: '10px'
                }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      // backgroundColor: colors.BTN_CONTAINER,
                      background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
                      color: "white",
                      boxShadow: 4,
                      p: 2,
                    }}
                    onClick={() => stakeToken(3)}
                  >
                    {/* <i class="fal fa-arrow-alt-from-left"></i> */}
                    <img
                      style={{
                        width: 20,
                        marginRight: 10,
                        transform: "rotate(180deg)",
                      }}
                      src={iconDown}
                    />
                    Stake Optimus Prime
                  </Button>
                </Box>
              </Box>
              </>
            )}
            {new BigNumber(stakedAmount) > 0 && (
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
                  color: "white",
                  boxShadow: 4,
                  p: 2,
                }}
                // onClick={unstakeToken}
                onClick={()=>setOpenUnstakeModal(true)}
              >
                <img style={{ width: 20, marginRight: 10 }} src={iconDown} />
                Unstake
              </Button>
            )}
          </div>
        </Card>
        <Card
          elevation={5}
          sx={{
            ml: { xs: 0, lg: 2 },
            width: { xs: "100%", lg: "50%" },
            borderRadius: 5,
            mt: { xs: 5, lg: 0 },
          }}
        >
          <CardContent
            style={{
              display: "flex",
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                color: colors.BLACK,
                p: 2,
                fontWeight: "bold",
              }}
            >
              Statistics
            </Typography>
          </CardContent>
          <CardContent
            style={{ display: "flex", flexDirection: "row", marginTop: -20 }}
          >
            <Typography
              sx={{
                fontSize: 15,
                color: colors.CONTAINER_LIGHT,
                pl: 2,
                fontWeight: "normal",
              }}
            >
              Total Stakers
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                color: colors.BTN_CONTAINER,
                pr: 2,
                pl: 2,
                fontWeight: "bold",
              }}
            >
              {stakersActive} Stakers
            </Typography>
          </CardContent>

          <CardContent
            style={{ display: "flex", flexDirection: "row", marginTop: -20 }}
          >
            <Typography
              sx={{
                fontSize: 15,
                color: colors.CONTAINER_LIGHT,
                pl: 2,
                fontWeight: "normal",
              }}
            >
              Total Staked
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                color: colors.BTN_CONTAINER,
                pr: 2,
                pl: 2,
                fontWeight: "bold",
              }}
            >
              {parseFloat(totalStaked).toFixed(3)} TUP
            </Typography>
          </CardContent>

          <CardContent
            style={{ display: "flex", flexDirection: "row", marginTop: -20 }}
          >
            <Typography
              sx={{
                fontSize: 15,
                color: colors.CONTAINER_LIGHT,
                pl: 2,
                fontWeight: "normal",
              }}
            >
              Teletraan Current APY
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                color: colors.BTN_CONTAINER,
                pr: 2,
                pl: 2,
                fontWeight: "bold",
              }}
            >
              {currentAPY0}%
            </Typography>
          </CardContent>

          <CardContent
            style={{ display: "flex", flexDirection: "row", marginTop: -20 }}
          >
            <Typography
              sx={{
                fontSize: 15,
                color: colors.CONTAINER_LIGHT,
                pl: 2,
                fontWeight: "normal",
              }}
            >
              Bumblebee Current APY
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                color: colors.BTN_CONTAINER,
                pr: 2,
                pl: 2,
                fontWeight: "bold",
              }}
            >
              {currentAPY1}%
            </Typography>
          </CardContent>

          <CardContent
            style={{ display: "flex", flexDirection: "row", marginTop: -20 }}
          >
            <Typography
              sx={{
                fontSize: 15,
                color: colors.CONTAINER_LIGHT,
                pl: 2,
                fontWeight: "normal",
              }}
            >
              Megatron Current APY
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                color: colors.BTN_CONTAINER,
                pr: 2,
                pl: 2,
                fontWeight: "bold",
              }}
            >
              {currentAPY2}%
            </Typography>
          </CardContent>

          <CardContent
            style={{ display: "flex", flexDirection: "row", marginTop: -20 }}
          >
            <Typography
              sx={{
                fontSize: 15,
                color: colors.CONTAINER_LIGHT,
                pl: 2,
                fontWeight: "normal",
              }}
            >
              Optimus Prime Current APY
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                color: colors.BTN_CONTAINER,
                pr: 2,
                pl: 2,
                fontWeight: "bold",
              }}
            >
              {currentAPY3}%
            </Typography>
          </CardContent>
        </Card>
      </div>
      <Card
        elevation={5}
        sx={{
          minWidth: 20,
          marginTop: 10,
          borderRadius: 5,
          marginTop: 5,
          backgroundImage: "linear-gradient(#05b4ef, #1072ac)",
        }}
      >
        <CardContent>
          <Typography
            sx={{
              fontSize: 18,
              color: colors.BLACK,
              p: 2,
              fontWeight: "bold",
              color: colors.WHITE,
            }}
          >
            Staking
          </Typography>
        </CardContent>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "row",
            justofyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {/* <div style={{overflow: 'hidden', height: "100%" }}>
            <img style={{position: 'absolute', right:-70, top:0}} src={bg_claim} />
            </div> */}
          <Typography
            sx={{
              fontSize: 15,
              color: colors.CONTAINER_LIGHT,
              pl: 2,
              fontWeight: "normal",
              color: colors.WHITE,
            }}
          >
            {new BigNumber(stakedAmount) > 0
              ? `You've staked ${
                parseFloat( new BigNumber(stakedAmount)).toFixed(1)
                } TUP. Your total claimable reward is ${parseFloat(
                  new BigNumber(rewardAmount) / 10 ** 18
                ).toFixed(5)} TUP`
              : "You do not have staked TUP yet. Stake your TUP to earn reward."}
          </Typography>
          {new BigNumber(stakedAmount) > 0 && (
            <div
              style={{
                display: "flex",
                width: "55%",

                justifyContent: "end",
              }}
            >
              <Button
                onClick={claimReward}
                
                variant="contained"
                sx={{
                  ml: 5,
                  mr: 0,
                  backgroundColor: colors.LIGHTGREY,
                  width: "40%",
                  color: "white",
                  boxShadow: 4,
                  p: 2,
                  borderRadius: 3,
                  color: colors.DARKGREY,
                  "&:hover": {
                    color: "white",
                    backgroundColor: colors.CONTAINER_LIGHT,
                  },
                }}
              >
                <img src={save} style={{width: 20, marginRight: 10}} /> 
                Claim
              </Button>
              
              <Button
                onClick={reInvestReward}
                
                variant="contained"
                sx={{
                  ml: 3,
                  mr: 0,
                  backgroundColor: colors.LIGHTGREY,
                  width: "40%",
                  color: "white",
                  boxShadow: 4,
                  p: 2,
                  borderRadius: 3,
                  color: colors.DARKGREY,
                  "&:hover": {
                    color: "white",
                    backgroundColor: colors.CONTAINER_LIGHT,
                  },
                }}
              >
                <img src={save} style={{width: 20, marginRight: 10}} /> 
                Re-invest
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
      <Card
        elevation={5}
        sx={{
          minWidth: 20,
          marginTop: 10,
          borderRadius: 5,
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        <CardContent>
          <Typography
            sx={{
              fontSize: 18,
              color: colors.BLACK,
              p: 2,
              fontWeight: "bold",
            }}
          >
            Staking
          </Typography>
        </CardContent>
        <div
          style={{
            paddingLeft: 30,
            paddingBottom: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ width: "100%" }}>
            
            <CardContent style={{ paddingLeft: '0px', display: "flex", flexDirection: "row" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  color: colors.CONTAINER_LIGHT,
                  fontWeight: "normal",
                }}
              >
                Enter amount to stake
              </Typography>
            </CardContent>
            <input
            type="number"
              value={userReward}
              onChange={(e) => setInputReward(e.target.value)}
              style={{
                border: "none",
                borderBottom: `1px solid ${colors.CONTAINER_LIGHT}`,
                outline: "none",
                fontSize: 15,
              }}
            ></input>

            <CardContent style={{ paddingLeft: '0px', display: "flex", flexDirection: "row" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  color: colors.CONTAINER_LIGHT,
                  fontWeight: "normal",
                }}
              >
                Select Package to stake in
              </Typography>
            </CardContent>
            <FormControl>
              <InputLabel id="calculate-pkg-label">PKG</InputLabel>
              <Select
                labelId="calculate-pkg-label"
                id="calculate-pkg"
                label="calculate-pkg"
                value={calcPkg}
                onChange={e => setCalcPkg(parseInt(e.target.value))}
              >
                <MenuItem value={0}> Teletraan </MenuItem>
                <MenuItem value={1}> Bumblebee </MenuItem>
                <MenuItem value={2}> Megatron </MenuItem>
                <MenuItem value={3}> Optimus Prime </MenuItem>
              </Select>
            </FormControl>

          </div>

          <Button
            onClick={calculateRew}
            variant="contained"
            sx={{
              ml: 5,
              mr: 5,
              background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
              width: "30%",
              color: "white",
              boxShadow: 4,
              p: 2,
              borderRadius: 3,
            }}
          >
            <img style={{ width: 20, marginRight: 10 }} src={calc} />
            Calculate
          </Button>
        </div>
        {show && (
          <div
            style={{
              display: "flex",
              justifyConent: "center",
              flexDirection: "column",
              marginLeft: 30,
              marginBottom: 20,
              width: "100%"
            }}
          >
            <p style={{display: 'flex', justifyContent: 'end'}}>
              Your reward upon staking {oSt} TUP will be{" "}
             <span style={{marginLeft: 'auto', paddingRight: 80}}>{parseFloat(rwrd * 1).toFixed(5)} TUP/day </span>
            </p>
            <p style={{display: 'flex', justifyContent: 'end'}}>
              Your reward upon staking {oSt} TUP will be{" "}
              <span style={{marginLeft: 'auto', paddingRight: 80}}> {parseFloat(rwrd * 365).toFixed(5)} TUP/annually</span>
            </p>
            <p style={{display: 'flex', justifyContent: 'end'}}>
              Your reward upon staking {oSt}
              TUP will be 
              <span style={{marginLeft: 'auto', paddingRight: 80}}>{parseFloat((rwrd * 365) / 2).toFixed(5)} TUP/semi
              annually </span>
            </p>
            <p style={{display: 'flex', justifyContent: 'end'}}>
             Your reward upon staking {oSt}
              TUP will be  <span style={{marginLeft: 'auto', paddingRight: 80}}> {parseFloat((rwrd * 365) / 4).toFixed(5)} TUP/quaterly </span>
            </p>
          </div>
        )}
      </Card>
      <Loader open={loader} />
      <StakeMore alert={alert} getStats={getStats} getStakerInfo={getStakerInfo} getBalance={getBalance} isApproved={isApproved} setLoader={setLoader} isLowOnWidth={false} open={stakeMoreModal} setOpen={setStakeMoreModal} />
    
      {/* unstake modal */}
      <Modal
        open={openUnstakeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...style, width: true ? {sx: "100%", md:"30%" } : {sx: "100%", md: "50%"} }}>
        <IconButton
          aria-label="close"
          onClick={()=>setOpenUnstakeModal(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ width: "100%" }}>
            
            <CardContent style={{ paddingLeft: '0px', display: "flex", flexDirection: "row" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  color: colors.CONTAINER_LIGHT,
                  fontWeight: "normal",
                }}
              >
                Enter amount to Un-stake
              </Typography>
            </CardContent>
            <input
            type="number"
              value={UnStakeVal}
              onChange={(e) => setUnStakeVal(e.target.value)}
              style={{
                border: "none",
                borderBottom: `1px solid ${colors.CONTAINER_LIGHT}`,
                outline: "none",
                fontSize: 15,
              }}
            ></input>

          

          </div>
            <Button
              variant="contained"
              sx={{
                background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",
                width: "100%",
                color: "white",
                height: 50,
                marginTop: 3,
              }}
              onClick={unstakeTokenAmount}
            >
              Unstake Amount
            </Button>
            <Button
              variant="contained"
              sx={{
                background : "linear-gradient(90deg, rgba(22,22,213,1) 0%, rgba(0,212,255,1) 100%)",

                width: "100%",
                height: 50,
                color: "white",
                marginTop: 3,
              }}
              onClick={unstakeToken}
            >
              Unstake Whole Amount
            </Button>
        </Box>
      </Modal>
      {/* unstake modal */}
    </>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {sx: "80%", md: "50%"},
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 40,
  p: 4,
  outline: 'none'
};

export default Virtual;
