import { combineReducers } from "redux";

import userReducer from "./users/reducer";
import userAmountReducer from "./userAmount/reducer";

const reducers = combineReducers({
  userReducer,
  userAmountReducer
});

export default reducers;
