import { makeStyles } from "@mui/styles";
import colors from "../../config/colors";

const useStyles = makeStyles((theme) => ({
  card: {
    
  },
}));

export default useStyles;
