import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { useDispatch, useSelector } from "react-redux";
import store, { persistor } from "./redux/index";
import Router from "./router";
import colors from "./config/colors";
import "./App.css";
import { BrowserRouter, useLocation } from "react-router-dom";
import axios from "axios";

function App() {
  const Location = useLocation;
  const theme = createTheme({
    // palette: {
    //   primary: {
    //     main: colors.PRIMARY,
    //   },
    //   secondary: {
    //     main: colors.PRIMARY,
    //   },
    //   background: colors.PRIMARY,
    // },
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
