import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Logo from "../../images/logoWOT.png";
import "./style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", md: "40%", lg: "30%" },
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 40,
  outline: 'none',
  p: 4,
};

export default function Loader(props) {
  const { open } = props;

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img className="loader"src={Logo} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
