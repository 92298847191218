export const checkBitKeepInstalled = async (alert) => {
  try {
    const isBitKeepInstalled = window.isBitKeep && !!window.bitkeep.ethereum;
    if (isBitKeepInstalled) {
      return window.bitkeep.ethereum;
    } else return false;
  } catch (e) {
    return false;
  }
};

export const initializeBitKeep = async (alert) => {
  try {
    const bitKeepInstalled = await checkBitKeepInstalled();

    if (!bitKeepInstalled) {
      throw new Error(false);
    }

    const provider = window.bitkeep && window.bitkeep.ethereum;

    if (!provider) {
      throw new Error(false);
    }

    return provider;
  } catch (e) {
    return false;
  }
};

export const checkChain_bitkeep = async (alert) => {
  const isBitKeepInstalled = window.isBitKeep && !!window.bitkeep.ethereum;

  if (isBitKeepInstalled) {
    return window.bitkeep.ethereum.chainId;
  } else {
    return false;
  }
};
