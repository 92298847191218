import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Drawer from "../../components/Drawer";
import colors from "../../config/colors";
import useStyles from "./style";

const Presale = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <Drawer>
        <PresaleComp />
      </Drawer>
    </>
  );
};

const PresaleComp = (props) => {
  return (
    <>
        <div>
          <Card
            elevation={5}
            sx={{
              width: { sm: 500, lg: "auto" },
              borderRadius: 5,
            }}
          >
            <CardContent
              style={{
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10
              }}
            >
              <Typography
                sx={{
                  fontSize: 18,
                  color: colors.BLACK,
                  p: 2,
                  fontWeight: "normal",
                }}
              >
                Presale Completed
              </Typography>
            </CardContent>
          </Card>
        </div>
    </>
  );
};
export default Presale;
